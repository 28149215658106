body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  padding-top: 60px;
}

h1 {
    font-size: 18px;
    font-weight: bolder;
    margin-top: 0px;
    margin-bottom: 5px;
}

h2 {
    font-size: 18px;
    font-weight: bolder;
    margin-top: 0px;
    margin-bottom: 0px;
}

table {
    width: 100%;
    margin-bottom: 50px;
}

table, th, td {
  border: 1px solid black;
}

th {
    background-color: lightgray;
}

th, tr {
    width: 100%;
}

th, td {
    padding: 0px 2px 0px 2px;
    text-align: left;
}

.group-by, .font-size {
    font-size: 18px;
}

.group-by label, .font-size label {
    padding: 2px 10px 2px;
}

.navbar-brand {
  padding: 10px;
}

.navbar-brand img {
  float: left;
  width: 30px;
}

.navbar-brand a {
  padding-left: 10px;
  vertical-align: sub;
  color: white;
  text-decoration: none;
}

.navbar-collapse.in {
  overflow: hidden;
  max-height: none !important;
  height: auto !important;
}

/* Start of always hamburger menu */
@media (min-width: 768px) {
  .navbar-header {
      float: none;
  }
  .navbar-left,.navbar-right {
      float: none !important;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .collapse.in{
      display:block !important;
  }
}
/* End of always hamburger menu */

.page-break {
    display: none;
}

@media print {
    .no-print {
        display: none;
    }

    .page-break {
        display: inherit;
        page-break-before: always;
    }
  }

